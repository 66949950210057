/* 
This syle sheet is used for elements above App.js (at the top of the DOM).
Everything in the global styles.js could be put in here but with the lack of
CSS Nesting support in current browsers, it is easier to use a Styled component
to do the nesting.
*/

/* 
  Define theme colors here from now on.
  Define font sizes and line heights here that should change dynamically with the browser's font size setting.
  These rem values are based off of 18px base.
  Screen widths would have been defined here if they could. styleProps.json is used for that.
  Due to the above reason for screen sizes, in index.css and global styles.js, screen sizes have to
  be typed out explicitly. Only in child components can we reuse the props in styleProps.json.
  */
:root {
  --black_1: #000000;
  --blue_1: #235d86;
  --blue_2: #e0e7ee;
  --blue_3: #f5f7f9;
  --blue_4: #487ba4;
  --gray_1: #424242;
  --gray_2: #cfdbe3;
  --gray_3: #f0f0f0;
  --gray_4: #363636;
  --gray_5: #838383;
  --green_1: #0b8719;
  --red_1: #d32f2f;
  --white_1: #ffffff;
  --white_2: #f1f1f1;
  --yellow_1: #face00;
  --font_size_10px: 0.5556rem;
  --font_size_16px: 0.8889rem;
  --font_size_18px: 1rem;
  --font_size_20px: 1.1111rem;
  --font_size_25px: 1.3889rem;
  --font_size_50px: 2.7778rem;
  --line_height_20px: 1.1111rem;
  --line_height_20_25px: 1.125rem; /* 20.25px but cannot use "." in var name */
  --line_height_22px: 1.2222rem;
  --line_height_22_5px: 1.25rem; /* 22.5px but cannot use "." in var name */
  --line_height_23px: 1.2778rem;
  --line_height_23_148px: 1.286rem;
  --line_height_23_4px: 1.3rem;
  --line_height_24px: 1.3333rem;
  --line_height_25px: 1.3889rem;
  --line_height_26px: 1.4444rem;
  --line_height_36px: 2rem;
  --line_height_45px: 2.5rem;
  --line_height_54px: 3rem;
}

* {
  box-sizing: border-box;
  font-family: "proxima-nova", "Oswald" !important;
  margin: 0;
  padding: 0;
  border: 0;
}

body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 112.5%; /* Changing the base to 18px */
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-size: var(--font_size_18px) !important;
  /* 
  Allows most of the body to be 18px (with standard browser font size settings) This also honors the user's browser font size preference in the html class
  In the global styled component, there are some Mui overrides to keep the font size at this rem value too. When the user increases or decreases their
  default browser font size, this rem value scales accordingly.
  */
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 100%;
  }
}

h2 {
  font-size: var(--font_size_20px);
  line-height: var(--line_height_26px);
  font-weight: 700;
}

h4 {
  font-weight: 700;
  line-height: var(--line_height_22px);
  color: var(--blue_1);
}

p,
th,
td {
  line-height: var(--line_height_22px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
Unfortunately for the below classes, this is where they have to be defined to take effect.
Some could be in src/styles.js but the reason they are here are because they are shared with
components, like Dialog, which are at the very top of the DOM hierarchy.
*/
.error-dialog .MuiPaper-root {
  max-width: 30.5556rem;
  height: 19.4444rem;
}

.error-dialog table td {
  padding: 5px 10px;
  vertical-align: top;
}
.error-dialog table th {
  padding: 25px 10px 5px 10px;
  /* background-color: var(--blue_2); */
  margin-top: 15px;
  vertical-align: top;
}
.error-dialog-title {
  background-color: var(--blue_1);
  color: var(--white_1);
  display: flex;
  align-items: center;
  line-height: var(--line_height_23_4px) !important;
  padding: 20px 45px !important;
}
.error-dialog-title svg {
  transform: scale(1.2);
  margin-right: 15px;
}
.error-dialog-content {
  margin-top: 15px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.error-dialog-content p {
  line-height: var(--line_height_26px);
}
.error-dialog-content .MuiDialogContentText-root {
  color: var(--black_1);
  white-space: break-spaces;
}
.error-dialog-actions {
  margin-bottom: 15px;
}
.error-dialog-button {
  width: 11.1111rem;
}
@media only screen and (max-width: 600px) {
  .error-dialog-buttons-container div {
    flex-basis: 100%;
    min-width: 100%;
    display: inline-flex;
    justify-content: center;
  }
  .error-dialog-buttons-container {
    flex-direction: column-reverse !important;
  }
  .error-dialog-buttons-container div button {
    width: 90%;
  }
  .error-dialog-title {
    padding: 20px 20px !important;
  }
  .error-dialog-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.btn-no-fill {
  border: 2px solid var(--black_1) !important;
  color: var(--black_1) !important;
  font-weight: 700 !important;
  font-size: var(--font_size_16px) !important;
  border-radius: 0 !important;
  padding: 8px 30px !important;
}

.btn-no-fill:hover {
  background-color: var(--gray_1) !important;
  color: var(--white_1) !important;
}

.btn-fill {
  border: 2px solid var(--black_1) !important;
  background-color: var(--gray_1) !important;
  color: var(--white_1) !important;
  font-weight: 700 !important;
  font-size: var(--font_size_16px) !important;
  border-radius: 0 !important;
  padding: 8px 30px !important;
}

.btn-fill:hover {
  background-color: var(--white_1) !important;
  color: var(--black_1) !important;
}

/* Styles for the elements within the Drawer Component in Header.jsx. Like Dialog, need to be made here */
.responsive-side-btn {
  padding: 16px;
  background-color: var(--blue_1);
}
.responsive-close-btn {
  margin-left: auto !important;
  display: block !important;
  padding: 0 !important;
}
.close-icon {
  width: 1.25em !important;
  height: 1.25em !important;
  color: var(--white_1);
}
button.logout-button {
  flex-basis: 16%;
  background-color: var(--yellow_1);
  border: 0;
  border-radius: 0;
  outline: 0;
  font-size: var(--font_size_20px);
  color: var(--black_1);
  text-align: center;
  font-weight: 800;
  line-height: var(--line_height_25px);
  text-transform: none;
  /* 
  Had to re-define this logout-button class since the original implmentation could not be use
  Due to complicated structure of original implementation, might be best to leave the
  structure alone and just redfine the class instead of making generic class ONLY THIS TIME.
  */
}
@media only screen and (max-width: 1228px) {
  button.logout-button {
    min-width: 13.75rem;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .header-text {
    font-size: 14px !important; /* Always want this to be 14px */
    color: var(--white_1);
    line-height: var(--line_height_23_148px) !important;
  }
  .header-text a {
    color: var(--white_1);
  }
}
.header-text-container {
  max-width: 13.75rem;
}
